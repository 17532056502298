import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environment/environment';
import { TranslateService } from '@ngx-translate/core';
import { referencesEnvironment } from '@references/environment/environment.references';
import { PublicHiringFirmsService } from '@references/shared/services/public/hiring-firms.service';
import { hexToRGB } from '@shared/helpers/utils.helper';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {
  @LocalStorage() public lng: string;

  @SessionStorage() private hiringFirm: IHiringFirm;

  public readonly IS_PRODUCTION: boolean = Boolean(environment.IS_PRODUCTION);
  public readonly VERSION: string = String(environment.VERSION);

  public readonly tokenName: string = String('recruiter-token');

  public readonly ENDPOINTS_ASSETS: string = String(
    environment.ENDPOINTS.ASSETS
  );

  public readonly ENDPOINTS_APP: string = String(
    environment.ENDPOINTS.FRONT_END.APP
  );

  public readonly ENDPOINTS_REFERENCES: string = String(
    environment.ENDPOINTS.FRONT_END.REFERENCES
  );

  public HOSTNAME = String(this._commonEnvironments.getHostname());
  public IS_VITAY = Boolean(this.HOSTNAME.indexOf('vitay') > -1);
  public IS_UK_SERVER = Boolean(
    window.location.hostname.indexOf('.co.uk') > -1
  );

  public IS_HIRING_FIRM_UNLOCKED = Boolean(
    ['vitay', 'mintzscreeningservices'].includes(this.HOSTNAME) || false
  );

  public logo: string;

  constructor(
    @Inject(DOCUMENT) private readonly _document: any,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient,
    private readonly _title: Title,
    private readonly _translate: TranslateService,
    private readonly _publicHiringFirms: PublicHiringFirmsService
  ) {}

  public getIPAddress(): Observable<any> {
    if (this.IS_PRODUCTION) {
      return this._http
        .get('https://ipinfo.io/json')
        .pipe(map((res: any) => res));
    }

    return new Observable((obs: Observer<any>) =>
      obs.next({ ip: '127.0.0.1' })
    );
  }

  public setLng(lng: string, isStoring: boolean = true) {
    lng = lng.match(/en|es|fr/) ? lng : 'en';

    this._translate.setDefaultLang(lng);
    this._translate.use(lng);

    if (isStoring) {
      this.lng = lng;
    }
  }

  public setTheme() {
    this.setFavicon();
    this.setLogo();
    this.setTitle();
    this.setStylesheet();
  }

  public setStylesheet(settings?: ISetting) {
    let isUpdated = true;
    if (!this.IS_VITAY) {
      if (!settings) {
        isUpdated = false;
        settings = this.hiringFirm?.settings;
      }

      const colorsPrimary = settings?.colors_primary || '#1CB9C8';
      const colorsSecondary = settings?.colors_secondary || '#333333';
      const fontFamily =
        settings?.font_family || '"Open Sans", Arial, Helvetica, sans-serif';

      const isWhiteLabelEnable =
        settings?.is_plugins_white_label_enabled || false;

      if (isWhiteLabelEnable) {
        const head = this._document.getElementsByTagName('head')[0];

        const css = `
            html, body { font-family: ${fontFamily} !important; }
            .v-btn-primary { background-color: ${colorsPrimary} !important; }
            .v-btn-primary:not(.disabled):hover { background-color: ${colorsSecondary} !important; }
            .v-btn-outline-primary { color: ${colorsPrimary} !important; border-color: ${colorsPrimary} !important; }
            .v-btn-outline-primary:hover { background-color: ${colorsPrimary} !important; }
            a:hover { color: ${colorsPrimary} !important; }
            .page-item .page-link { color: ${colorsPrimary} !important; }
            .page-item.active .page-link { color: white !important; border-color: ${colorsPrimary} !important; background: ${colorsPrimary} !important; }
            .loading .spinner div { background-color: ${colorsPrimary} !important; }
            .navbar .v-nav-item:hover a i, .navbar .v-nav-item.active a i, .topbar .v-nav-item:hover .nav-link i, .topbar .v-nav-item.active .nav-link i { color: ${colorsPrimary} !important; }
            tabset .nav-pills .nav-link.active { background-color: ${hexToRGB(
              colorsPrimary,
              0.15
            )} !important; color: ${colorsPrimary} !important; }
            .v-alert-primary { color: ${colorsPrimary} !important; background-color: ${hexToRGB(
          colorsPrimary,
          0.4
        )} !important; }
            .integrations-card--isNotAvailable { background-color: ${colorsPrimary} !important; }
            .v-text-primary { color: ${colorsPrimary} !important; }
            .v-text--highlight { color: ${colorsPrimary} !important; }
            .v-table-hover tbody tr:hover { background-color: ${hexToRGB(
              colorsPrimary,
              0.05
            )} !important; }
            .form-control:focus { background-color: ${hexToRGB(
              colorsPrimary,
              0.1
            )} !important; border-color: ${colorsPrimary} !important; }
          `;

        const styleId = 'white-label-stylesheet';
        const style = this._document.createElement('style');
        style.type = 'text/css';
        style.id = styleId;

        style.appendChild(document.createTextNode(css));

        const isStyleExist = !!this._document.getElementById(styleId);

        if (!isStyleExist || isUpdated) {
          head.appendChild(style);
          this.hiringFirm = this.hiringFirm;
        }
      }
    }
  }

  public setScripts() {
    const head = this._document.getElementsByTagName('head')[0];
    const body = this._document.getElementsByTagName('body')[0];

    if (this.IS_PRODUCTION) {
      const scriptGATag = this._document.createElement('script');
      scriptGATag.type = 'text/javascript';

      let gaTagInnerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});`;
      gaTagInnerHTML += `var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;`;
      gaTagInnerHTML += `j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})`;
      gaTagInnerHTML += `(window, document, 'script', 'dataLayer', '${referencesEnvironment.GOOGLE_ANALYTICS.GTM}');`;

      const referenceFormUrls = ['forms', 'reference-requests', 'references'];
      const url = window.location.href.split('/')[3];

      // Only include on recruiter pages
      if (!referenceFormUrls.includes(url)) {
        // Beamer
        const beamerConfigTag = this._document.createElement('script');
        beamerConfigTag.innerHTML = `var beamer_config = { product_id : "qoBvPNpS53935", selector: ".beamerTrigger" };`;

        const beamerScript = this._document.createElement('script');
        beamerScript.type = 'text/javascript';
        beamerScript.src = 'https://app.getbeamer.com/js/beamer-embed.js';
        beamerScript.defer = 'defer';

        body.appendChild(beamerConfigTag);
        body.appendChild(beamerScript);
      }

      // HelpScout Beacon
      let helpScoutTag = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}
        if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}
        (window,document,window.Beacon||function(){});`;
      helpScoutTag += `window.Beacon('init', '2dd0e0c9-b62d-45d2-8811-e3f5fa17612d')`;
      gaTagInnerHTML += helpScoutTag;

      scriptGATag.innerHTML = gaTagInnerHTML;

      const noScriptGATag = this._document.createElement('noscript');
      noScriptGATag.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${referencesEnvironment.GOOGLE_ANALYTICS.GTM}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      body.appendChild(noScriptGATag);
      head.appendChild(scriptGATag);
    }

    // Google Places
    const scriptGASearch = this._document.createElement('script');
    scriptGASearch.type = 'text/javascript';
    scriptGASearch.src = `https://maps.googleapis.com/maps/api/js?key=${referencesEnvironment.GOOGLE_SEARCH.key}&libraries=places&language=en"`;

    head.appendChild(scriptGASearch);
  }

  public resolve(): Observable<IHiringFirm[]> {
    if (!this.IS_VITAY && !this.hiringFirm) {
      return this._publicHiringFirms.get();
    }

    return;
  }

  private setFavicon() {
    let href = 'favicon.ico';
    if (!this.IS_VITAY) {
      href = `${this.ENDPOINTS_ASSETS}/images/white-label/${this.HOSTNAME}/favicon.ico`;
    }

    const favicon = this._document.getElementById('appFavicon');
    if (!!favicon) {
      favicon.setAttribute('href', href);
    }
  }

  private setLogo() {
    let url = `${this.ENDPOINTS_ASSETS}/images/white-label/${this.HOSTNAME}/logo.svg`;
    if (this.IS_VITAY) {
      url = `${this.ENDPOINTS_ASSETS}/images/vitay.io/logo-green.svg`;
    }

    return (this.logo = url);
  }

  private setTitle() {
    const titles: any = {
      vitay: 'Vitay.io',
      refchx: 'RefChx',
      mintzscreeningservices: 'Mintz Global Services',
      calgarystampede: 'Calgary Stampede',
      screeningcanada: 'Screening Canada',
      plantoprotectscreening: 'Plan To Protect',
      probitypeople: 'ProbityPeople',
      cpr: 'Canadian Pacific Railway',
      mie: 'MIE'
    };

    this._title.setTitle(
      `${titles[this.HOSTNAME] || this.HOSTNAME} - References checked`
    );
  }
}

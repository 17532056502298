export const tableFields = {
  answers: [
    'id',
    'answer_set_id',
    'question',
    'type',
    'category',
    'value',
    'comment',
    'order',
    'language',
    'created_at',
    'updated_at'
  ],
  candidates: [
    'id',
    'internal_id',
    'first_name',
    'last_name',
    'email',
    'country_code',
    'phone',
    'created_at',
    'updated_at',
    'notes',
    'status',
    'assigned_recruiter_email'
  ],
  referenceAnswersPerCandidates: [],
  candidatesPerRecruiters: [],
  feedbacks: [
    'id',
    'internal_id',
    'identifiable_id',
    'identifiable_type',
    'requested_by_email',
    'question_set_title',
    'filled_at',
    'created_at',
    'updated_at',
    'requested_by_email'
  ],
  pre_screenings: [
    'id',
    'internal_id',
    'candidate_id',
    'requested_by_email',
    'question_set_title',
    'filled_at',
    'created_at',
    'updated_at',
    'status'
  ],
  references: [
    'id',
    'first_name',
    'last_name',
    'email',
    'country_code',
    'phone',
    'contact_type',
    'company_name',
    'candidate_id',
    'candidate_internal_id',
    'title',
    'requested_by_email',
    'relationship',
    'notes',
    'filled_at',
    'created_at',
    'updated_at'
  ]
};

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IPreScreening } from '@shared/interfaces/pre-screening.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ISort } from '@shared/interfaces/sort.interface';
import { ITypeahead } from '@shared/interfaces/typeahead.interface';
import { PreScreening } from '@shared/models/pre-screening.model';
import { Typeahead } from '@shared/models/typeahead.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreScreeningsService implements IPaginable {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/pre_screenings`
  );

  constructor(private readonly _http: HttpClient) {}

  public delete(id: string): Observable<IPreScreening> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => PreScreening.fromAPI(res)));
  }

  public find(id: string): Observable<IPreScreening> {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => PreScreening.fromAPI(res)));
  }

  public get(): Observable<IPreScreening[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3)
      .pipe(map((res: IAPIArrayData) => PreScreening.fromAPIArray(res)));
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter,
    _?: ISort,
    dates?: IFilter[]
  ): Observable<IPreScreening[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    if (dates) {
      dates.map((f: IFilter) => {
        if (!!f['value']) {
          params = params.set(`dates[${f['key']}]`, f['value']);
        }
      });
    }

    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3, { params })
      .pipe(
        tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
        map((res: IAPIArrayData) => PreScreening.fromAPIArray(res))
      );
  }

  public patch(body: IAPIData): Observable<IPreScreening> {
    const id = body.data.id;
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${id}`, body)
      .pipe(map((res: IAPIData) => PreScreening.fromAPI(res)));
  }

  public post(body: IAPIData): Observable<IPreScreening> {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V3, body)
      .pipe(map((res: IAPIData) => PreScreening.fromAPI(res)));
  }

  public sendPreScreeningReminder(id: APIuuid): Observable<IReminder> {
    return this._http
      .post<any>(
        `${environment.ENDPOINTS.BACK_END.V3}/reminders/pre_screenings/${id}`,
        {}
      )
      .pipe(map((res: any) => res));
  }

  public searchTypeahead(search: {
    data: { search_criteria: string };
  }): Observable<ITypeahead> {
    const url = this.ENDPOINT_V3.replace('pre_screenings', 'candidates');
    return this._http
      .post<IAPIData>(`${url}/search`, search)
      .pipe(map((res: IAPIData) => Typeahead.fromAPI(res)));
  }
}

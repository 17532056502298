import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment/environment';
import { AppModule } from '@references/app.module';
import { isLocalStorageAvailable } from '@shared/helpers/utils.helper';
import { throwError } from 'rxjs';

if (environment.IS_PRODUCTION) {
  enableProdMode();
  isLocalStorageAvailable();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: Error) => throwError(err));

import { DragulaModule } from 'ng2-dragula';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AnswersComponent } from '@shared/components/answers/answers.component';
import { CardComponent } from '@shared/components/card/card.component';
import { ContentComponent } from '@shared/components/content/content.component';
import { EmptyComponent } from '@shared/components/empty/empty.component';
import { EntriesComponent } from '@shared/components/entries/entries.component';
import { ErrorComponent } from '@shared/components/error/error.component';
import { FormErrorsComponent } from '@shared/components/form/errors/form.errors.component';
import { FormFormGroupComponent } from '@shared/components/form/form-group/form.form-group.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { ModalsAnswersComponent } from '@shared/components/modals/answers/modals.answers.component';
import { ModalsBackgroundCheckingRequestsComponent } from '@shared/components/modals/background-checking-requests/modals.background-checking-requests.component';
import { ModalsCandidatesComponent } from '@shared/components/modals/candidates/modals.candidates.component';
import { ModalsEmployersComponent } from '@shared/components/modals/employers/modals.employers.component';
import { ModalsFeedbackRequestsComponent } from '@shared/components/modals/feedback-requests/modals.feedback-requests.component';
import { ModalsComponent } from '@shared/components/modals/modals.component';
import { ModalsPreScreeningRequestsComponent } from '@shared/components/modals/pre-screening-requests/modals.pre-screening-requests.component';
import { ModalsQuestionGroupsComponent } from '@shared/components/modals/question-groups/modals.question-groups.component';
import { ModalsQuestionSetsComponent } from '@shared/components/modals/question-sets/modals.question-sets.component';
import { ModalsQuestionsComponent } from '@shared/components/modals/questions/modals.questions.component';
import { ModalsQuestionsPreviewComponent } from '@shared/components/modals/questions/preview/modals.questions.preview.component';
import { ModalsRecruitersComponent } from '@shared/components/modals/recruiters/modals.recruiters.component';
import { ModalsReferenceRequestsComponent } from '@shared/components/modals/reference-requests/modals.reference-requests.component';
import { ModalsUsersComponent } from '@shared/components/modals/users/modals.users.component';
import { ModalsVerificationRequestsComponent } from '@shared/components/modals/verification-requests/modals.verification-requests.component';
import { ModalsVerificationsComponent } from '@shared/components/modals/verifications/modals.verifications.component';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { PermissionsComponent } from '@shared/components/permissions/permissions.component';
import { PhoneNumberSignupComponent } from '@shared/components/phone-number-signup/phone-number-signup.component';
import { PhoneNumberComponent } from '@shared/components/phone-number/phone-number.component';
import { ScoreComponent } from '@shared/components/score/score.component';
import { TableComponent } from '@shared/components/table/table.component';
import { TypeaheadComponent } from '@shared/components/typeahead/typeahead.component';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { AgoPipe } from '@shared/pipes/ago.pipe';
import { DateFormatPipe } from '@shared/pipes/date.format.pipe';
import { KeysPipe } from '@shared/pipes/keys.pipe';
import { ChartModule } from 'angular-highcharts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SidebarModule } from 'ng-sidebar';
import { Ng5SliderModule } from 'ng5-slider';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { RatingModule } from 'ngx-bootstrap/rating';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ClipboardModule } from 'ngx-clipboard';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPasswordStrengthMeterModule } from 'ngx-password-strength-meter';
import { QuillModule } from 'ngx-quill';
import { NgxSelectModule } from 'ngx-select-ex';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FeatureFlagDirective } from './directives/feature-flag.directive';

const components = [
  // Pipes
  AgoPipe,
  DateFormatPipe,
  KeysPipe,

  // Directive
  AutofocusDirective,
  ButtonDirective,
  FeatureFlagDirective,

  // Components
  AnswersComponent,
  TypeaheadComponent,
  CardComponent,
  ContentComponent,
  EntriesComponent,
  EmptyComponent,
  ErrorComponent,
  FormErrorsComponent,
  FormFormGroupComponent,
  LoadingComponent,
  NotFoundComponent,
  PaginationComponent,
  PermissionsComponent,
  ScoreComponent,
  TableComponent,
  PhoneNumberComponent,
  PhoneNumberSignupComponent,

  // Modals
  ModalsComponent,
  ModalsAnswersComponent,
  ModalsCandidatesComponent,
  ModalsQuestionGroupsComponent,
  ModalsEmployersComponent,
  ModalsFeedbackRequestsComponent,
  ModalsPreScreeningRequestsComponent,
  ModalsBackgroundCheckingRequestsComponent,
  ModalsQuestionSetsComponent,
  ModalsQuestionsComponent,
  ModalsQuestionsPreviewComponent,
  ModalsRecruitersComponent,
  ModalsReferenceRequestsComponent,
  ModalsUsersComponent,
  ModalsVerificationsComponent,
  ModalsVerificationRequestsComponent
];

const modules = [
  BsDatepickerModule,
  ButtonsModule,
  PopoverModule,
  BsDropdownModule,
  CommonModule,
  CollapseModule,
  ClipboardModule,
  GooglePlaceModule,
  FormsModule,
  ModalModule,
  NgxMaskModule,
  Ng5SliderModule,
  NgCircleProgressModule,
  NgxPasswordStrengthMeterModule,
  NgxSelectModule,
  PaginationModule,
  RatingModule,
  ReactiveFormsModule,
  RouterModule,
  SidebarModule,
  SortableModule,
  TabsModule,
  TooltipModule,
  TypeaheadModule,
  QuillModule,
  UiSwitchModule
];

@NgModule({
  imports: [...modules, TranslateModule.forChild(), ChartModule, DragulaModule.forRoot(),],
  declarations: components,
  exports: [...components, ...modules, TranslateModule]
})
export class SharedModule {}
